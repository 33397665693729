import { gql } from 'urql'
export const FragGuideCard = gql`
  fragment GuideCard on GuideCard {
    __typename
    id
    imageUrl
    skills
    active
    title
    slug
    location
    countryFlagImageUrl
    navigationAction {
      url
    }
  }
`
