import { gql } from 'urql'
export const FragBookMultipleSessionsSection = gql`
  fragment BookMultipleSessionsSection on BookMultipleSessionsSection {
    items {
      __typename
      id
      sessionId
      title
      start
      booked
    }
  }
`
