import { gql } from 'urql'
export const FragCategoryIndexPageSection = gql`
  fragment CategoryIndexPageSection on CategoryIndexPageSection {
    __typename
    id
    title
    items {
      __typename
      id
      title
      slug
      backgroundColor
      iconUrl
    }
  }
`
