import { gql } from 'urql'
export const FragCommunityBannersSection = gql`
  fragment CommunityBannersSection on CommunityBannersSection {
    id
    items {
      link
      label
      bannerImageUrls {
        small
        large
      }
    }
  }
`

export const FragCommunityBannerCard = gql`
  fragment CommunityBannerCard on CommunityBannerCard {
    link
    label
    bannerImageUrls {
      small
      large
    }
  }
`
