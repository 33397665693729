import { gql } from 'urql'
export const FragSessionCard = gql`
  fragment SessionCard on SessionCard {
    sessionId
    id
    classId
    title
    imageUrl
    sponsor {
      id
      name
      description
      logoUrl
    }
    accessDisplay {
      __typename
      ... on LockedAccessDisplay {
        iconUrl
      }
    }
    rating
    start
    end
    durationInMinutes: durationMinutes
    guideByline
    guideAvatarUrl
    metaInfo {
      jsonLd {
        ... on SessionSchema {
          __typename
          context
          type
          name
          description
          url
          image
          startDate
          eventAttendanceMode
          location {
            type
            url
          }
        }
      }
    }
    navigationAction {
      __typename
      ... on NavigateToClassDetailAction {
        url
        classId
      }
    }
    primaryAction {
      label
      __typename
      ... on ViewClassDetailCardAction {
        url
      }
      ... on JoinBookedSessionCardAction {
        url
      }
      ... on JoinLiveSessionCardAction {
        url
      }
      ... on MustAuthenticateUserCardAction {
        url
      }
      ... on BookSessionCardAction {
        sessionId
      }
      ... on GetAccessSessionCardAction {
        dialogType
      }
    }
    secondaryAction {
      label
      __typename
      ... on CancelSessionBookingCardAction {
        sessionId
      }
    }
    navigateToGuideAction {
      __typename
      ... on NavigateToGuideAction {
        url
      }
    }
    navigateToAttendedSessionDetailAction {
      __typename
      ... on NavigateToAttendedSessionDetailAction {
        url
      }
    }
    isCommunityLedClass
    isEncore
    classIsNew
    livenessType
  }
`
