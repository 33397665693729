import { gql } from 'urql'
export const FragLiveGuidesSection = gql`
  fragment LiveGuidesSection on LiveGuidesSection {
    navigationActions {
      ... on SectionNavigationAction {
        label
        targetPageId
        theme
      }
    }
    items {
      __typename
      ... on GuideCard {
        ...GuideCard
      }
    }
  }
`
