import { gql } from 'urql'
export const FragGuidePageSection = gql`
  fragment GuidePageSection on GuidePageSection {
    items {
      __typename
      ... on GuideCard {
        ...GuideCard
      }
    }
  }
`
