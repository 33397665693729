import { gql } from 'urql'
export const FragPartnershipBannerSection = gql`
  fragment PartnershipBannerSection on PartnershipBannerSection {
    id
    partnershipBannerContent: content {
      ...PartnershipBannerContent
    }
  }

  fragment PartnershipBannerContent on PartnershipBannerContent {
    __typename
    partnerName
    title
    description
    link
    label
    logoUrl
    imageUrls {
      desktop
      tablet
      smallTablet
      mobile
    }
  }
`
