import { gql } from 'urql'

export const FragSessionNotesDetailsPageSection = gql`
  fragment SessionNotesDetailsPageSection on SessionNotesDetailsPageSection {
    notesHtml
    attachments {
      fileName
      url
      sizeInBytes
    }
  }
`
