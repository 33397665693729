import { gql } from 'urql'
export const FragAccessMessagePageSection = gql`
  fragment AccessMessageSection on AccessMessageSection {
    content {
      id
      title
      description
      iconUrl
      primaryAction {
        ... on UrlAction {
          url
          label
        }
      }
    }
  }
`
