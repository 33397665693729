import { gql } from 'urql'
export const FragSeriesPageSection = gql`
  fragment SeriesPageSection on SeriesPageSection {
    items {
      __typename
      ... on SeriesCard {
        ...SeriesCard
      }
    }
  }
`
