import { gql } from 'urql'
export const FragScheduleDay = gql`
  fragment ScheduleDay on ScheduleDaySection {
    date
    items {
      ... on SessionCard {
        ...SessionCard
      }
    }
  }
`
