import { gql } from 'urql'
export const FragCompanyInfoItem = gql`
  fragment CompanyInfoItem on CompanyInfoBlock {
    featuredLogos {
      title
      imageUrl
    }
    companyStats {
      title
      subtitle
      icon
    }
  }
`
