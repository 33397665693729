import { gql } from 'urql'
export const FragCategoryInfoSection = gql`
  fragment CategoryInfoSection on CategoryInfoSection {
    id
    content {
      id
      title
      slug
      backgroundColor
      iconUrl
    }
  }
`
