import { gql } from 'urql'

export const FragSessionNotesInfoPageSection = gql`
  fragment SessionNotesInfoPageSection on SessionNotesInfoPageSection {
    id
    sectionTitle: title
    subtitle
    catalogueLanguage
    content {
      id
      sessionId
      classId
      classSlug
      classTitle
      imageUrl
      durationMinutes
      start
      end
      guideAvatarUrl
      guideByline
      guideSlug
      navigationAction {
        __typename
        url
      }
      navigateToGuideAction {
        __typename
        ... on NavigateToGuideAction {
          url
        }
      }
    }
  }
`
