import { gql } from 'urql'
export const FragPricingPlanSection = gql`
  fragment PricingPageSection on PricingPageSection {
    id
    title
    subtitle
    items {
      ... on PricingPlanItem {
        __typename
        title
        subtitle
        featuresTitle
        features
        iconUrl
        primaryAction {
          url
          label
          type
          target
        }
        pricing {
          gsuPriceId
          displayPrice
          billingInterval
        }
        highlighted {
          label
        }
        isFree
        isNonPlan
      }
    }
  }
`
