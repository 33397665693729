import { gql } from 'urql'
export const FragHomeMetaInfo = gql`
  fragment HomeMetaInfo on MetaInfo {
    __typename
    title
    description
    image
    url
    robots
    jsonLd {
      __typename
      ... on HomeSchema {
        context
        description
        name
        type
        url
        potentialAction {
          __typename
          queryInput
          target
          type
        }
        sameAs
      }
    }
  }
`

export const FragPageMetaInfo = gql`
  fragment PageMetaInfo on MetaInfo {
    __typename
    title
    description
    image
    url
  }
`

export const FragClassPageMetaInfo = gql`
  fragment ClassPageMetaInfo on MetaInfo {
    __typename
    title
    description
    image
    url
    robots
    jsonLd {
      __typename
      ... on ClassSchema {
        context
        description
        name
        type
        url
        provider {
          __typename
          type
          name
          sameAs
        }
      }
    }
  }
`
