import { gql } from 'urql'
export const FragSnippetSection = gql`
  fragment SnippetSection on SnippetPageSection {
    title
    id
    snippetItems: items {
      ... on Snippet {
        __typename
        title
        content
        primaryAction {
          url
          label
        }
        secondaryAction {
          url
          label
        }
      }
    }
  }
`
