import { gql } from 'urql'
export const FragGuideInfoPageSection = gql`
  fragment GuideInfoPageSection on GuideInfoPageSection {
    content {
      __typename
      name
      description
      imageUrl
      hasSessions
    }
  }
`
