import { gql } from 'urql'
export const FragSeriesCard = gql`
  fragment SeriesCard on SeriesCard {
    __typename
    id
    title
    slug
    imageUrl
    sponsor {
      id
      name
      description
      logoUrl
    }
    navigationAction {
      url
    }
  }
`
