import { gql } from 'urql'
export const FragPopularSeriesSection = gql`
  fragment PopularSeriesSection on PopularSeriesSection {
    navigationActions {
      ... on SectionNavigationAction {
        label
        targetPageId
        theme
      }
    }
    items {
      __typename
      ... on SeriesCard {
        ...SeriesCard
      }
    }
  }
`
