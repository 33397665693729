import { gql } from 'urql'

export const FragSponsorInfoPageSection = gql`
  fragment SponsorInfoPageSection on SponsorInfoPageSection {
    __typename
    id
    infoContent: content {
      ...SponsorInfoContent
    }
  }
  fragment SponsorInfoContent on SponsorInfoContent {
    __typename
    id
    name
    description
    logoUrl
  }
`
