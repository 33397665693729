import { gql } from 'urql'
export const FragMicroLearningShortsSection = gql`
  fragment MicroLearningShortsSection on MicroLearningShortsSection {
    id
    title
    items {
      link
    }
  }
`

export const FragMicroShortCard = gql`
  fragment MicroShortCard on MicroShortCard {
    link
  }
`
