import { gql } from 'urql'
export const FragHelpInfoSection = gql`
  fragment HelpInfoSection on HelpInfoPageSection {
    title
    id
    helpInfoItems: items {
      ... on HelpInfo {
        __typename
        title
        primaryAction {
          url
          label
        }
        secondaryAction {
          url
          label
        }
      }
    }
  }
`
