import { gql } from 'urql'
export const FragSeriesInfoPageSection = gql`
  fragment SeriesInfoPageSection on SeriesInfoPageSection {
    content {
      __typename
      name
      description
      imageUrl
      hasSessions
    }
  }
`
