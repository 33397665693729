import { gql } from 'urql'
export const FragQuoteCard = gql`
  fragment QuoteCard on TestimonialCard {
    title
    quoteContent: content
    imageUrl
    subtitle
    attention: emphasised
  }
`
