import { gql } from 'urql'
export const FragImagePageSection = gql`
  fragment ImagePageSection on ImagePageSection {
    imageItems: items {
      ... on Image {
        imageUrl
        altText
      }
    }
    accessDisplay {
      __typename
      ... on LockedAccessDisplay {
        iconUrl
      }
    }
    isCommunityLedClass
  }
`
