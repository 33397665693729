import { gql } from 'urql'
export const FragHeroInfoPageSection = gql`
  fragment HeroInfoSection on HeroInfoSection {
    content {
      title
      description
      showVideo
      userStats {
        formattedActiveDays
        formattedTotalBookings
        formattedTotalMinutes
        isActiveUser
        isNewUser
        activeDays
        totalBookings
        totalMinutes
      }
      video {
        srcUrl
        posterUrl
      }
      primaryAction {
        url
        label
      }
      navigationActions {
        label
        url
      }
    }
  }
`
