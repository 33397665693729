import { gql } from 'urql'
export const FragFeaturedClassesSection = gql`
  fragment FeaturedClassesSection on FeaturedClassesSection {
    items {
      __typename
      title
      id
      title
      slug
      imageUrl
      upcomingSession {
        ...SessionCard
      }
      navigationAction {
        __typename
        url
      }
      primaryAction {
        label
        __typename
        ... on ViewClassDetailCardAction {
          url
        }
        ... on JoinBookedSessionCardAction {
          url
        }
        ... on JoinLiveSessionCardAction {
          url
        }
        ... on MustAuthenticateUserCardAction {
          url
        }
        ... on BookSessionCardAction {
          sessionId
        }
      }
    }
  }
`
