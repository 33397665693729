import { gql } from 'urql'
export const FragCategoryClassesSection = gql`
  fragment CategoryClassesSection on CategoryClassesSection {
    pageInfo {
      totalItems
      hasPrevPage
      hasNextPage
      page
    }
    items {
      ...SessionCard
    }
  }
`
